import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import {BillingColumn} from './BillingColumn';
import {ShippingColumn} from './ShippingColumn';
import * as s from './ShippingSection.scss';
import {isAddressEmpty} from '../../../addressUtils';
import {PickupColumn} from './PickupColumn';

export enum ShippingSectionDataHook {
  root = 'ShippingSectionDataHook.root',
}

export function ShippingSection() {
  const {
    billingAddress,
    address: shippingAddress,
    isOfflinePayment,
    totalPrice,
    pickupAddress,
    isPickupSelected,
    shouldEnableNewTyp,
  } = useControllerProps().thankYouPageStore;

  const isShippingAddressEmpty =
    shouldEnableNewTyp && isPickupSelected
      ? isAddressEmpty({
          addressLine1: pickupAddress.addressLine,
          city: pickupAddress.city,
          country: pickupAddress.country,
          zipCode: pickupAddress.zipCode,
        })
      : isAddressEmpty({
          addressLine1: shippingAddress.addressLine,
          addressLine2: shippingAddress.addressLine2,
          city: shippingAddress.city,
          country: shippingAddress.country,
          subdivision: shippingAddress.subdivision,
          zipCode: shippingAddress.zipCode,
        });

  const isBillingAddressEmpty = isAddressEmpty({
    addressLine1: billingAddress.addressLine1,
    addressLine2: billingAddress.addressLine2,
    city: billingAddress.city,
    country: billingAddress.country,
    subdivision: billingAddress.subdivision,
    zipCode: billingAddress.zipCode,
  });

  const shouldShowBilling = !isOfflinePayment && !isBillingAddressEmpty && totalPrice !== 0;

  return (
    <div className={s.section} data-hook={ShippingSectionDataHook.root}>
      {!isShippingAddressEmpty && (
        <Column>{shouldEnableNewTyp && isPickupSelected ? <PickupColumn /> : <ShippingColumn />}</Column>
      )}
      {shouldShowBilling && (
        <Column>
          <BillingColumn />
        </Column>
      )}
    </div>
  );
}

const Column = (props: any) => {
  return <div className={s.column}>{props.children}</div>;
};
