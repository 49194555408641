// eslint-disable-next-line wix-custom-rules/wixstores-file-names
import {TranslationFunction} from 'react-i18next';
import {disableTranslationEscaping} from './disableTranslationEscaping';

interface AddressObject {
  addressLine1: string;
  addressLine2?: string;
  country: string;
  subdivision?: string;
  zipCode: string;
  city: string;
}
export const getFormattedAddressTranslation = (
  {addressLine1, addressLine2, city, country, subdivision, zipCode}: AddressObject,
  t: TranslationFunction
) => {
  if (addressLine2 && subdivision) {
    return t(
      'thankYouPage.addressFormat.line2.withSubdivision',
      disableTranslationEscaping({
        addressLine1,
        addressLine2,
        city,
        zipCode,
        country,
        subdivision,
      })
    );
  }
  if (addressLine2) {
    return t(
      'thankYouPage.addressFormat.line2.withoutSubdivision',
      disableTranslationEscaping({
        addressLine1,
        addressLine2,
        city,
        zipCode,
        country,
      })
    );
  }
  if (subdivision) {
    return t(
      'thankYouPage.addressFormat.line1.withSubdivision',
      disableTranslationEscaping({
        addressLine1,
        city,
        zipCode,
        country,
        subdivision,
      })
    );
  }
  return t(
    'thankYouPage.addressFormat.line1.withoutSubdivision',
    disableTranslationEscaping({
      addressLine1,
      city,
      zipCode,
      country,
    })
  );
};

export const isAddressEmpty = ({
  addressLine1,
  addressLine2,
  city,
  country,
  subdivision,
  zipCode,
}: AddressObject): boolean => {
  return !addressLine1 && !addressLine2 && !city && !country && !subdivision && !zipCode;
};
