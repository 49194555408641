import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import {ShippingSectionText} from './ShippingSectionText';
import {useSettings} from 'yoshi-flow-editor-runtime/tpa-settings/react';
import settingsParams from '../../../settingsParams';
import {getFormattedAddressTranslation} from '../../../addressUtils';
import {useTranslation} from 'yoshi-flow-editor-runtime';

export enum BillingSectionDataHook {
  root = 'BillingSectionDataHook.root',
  title = 'BillingSectionDataHook.title',
  fullName = 'BillingSectionDataHook.fullName',
  address = 'BillingSectionDataHook.address',
  phone = 'BillingSectionDataHook.phone',
  company = 'BillingSectionDataHook.company',
}

export const BillingColumn = () => {
  const {t} = useTranslation();
  const {billingAddress} = useControllerProps().thankYouPageStore;
  const {get} = useSettings();
  const {addressLine1, addressLine2, city, country, subdivision, zipCode, fullName, phoneNumber, company} =
    billingAddress;

  const getBillingColumnTitle = () => {
    return get(settingsParams.THANK_YOU_PAGE_BILLING_ADDRESS_LABEL) as string;
  };

  const addressData = getFormattedAddressTranslation(
    {addressLine1, addressLine2, country, city, subdivision, zipCode},
    t
  );

  return (
    <div data-hook={BillingSectionDataHook.root}>
      <ShippingSectionText dataHook={BillingSectionDataHook.title} text={getBillingColumnTitle()} isTitle={true} />
      <ShippingSectionText dataHook={BillingSectionDataHook.fullName} text={fullName} />
      <ShippingSectionText dataHook={BillingSectionDataHook.address} text={addressData} />
      <ShippingSectionText dataHook={BillingSectionDataHook.phone} text={phoneNumber} />
      <ShippingSectionText dataHook={BillingSectionDataHook.company} text={company} />
    </div>
  );
};
